var App = {
    interaction: false,
    lockScroll: function(scrollFlag) {
        $('#scrollable-content').toggleClass('noscroll', scrollFlag);
    },
    toggleOverlay: function(showOverlay, overMenu) {
        $('#black-overlay')
            .toggleClass('hidden', !showOverlay)
            .toggleClass('over-menu', overMenu === true);
    },
    toggleInteraction: function(lockInteraction, selector, callback) {
        this.interaction = lockInteraction ? {callback: callback} : false;
    },

    startInteractionOverlay: function(selector) {
        $(selector).removeClass('hidden');

        App.lockScroll(true);
        App.toggleOverlay(true, true);
        App.toggleInteraction(true, selector);
    },
    endInteractionOverlay: function(selector) {
        $(selector).addClass('hidden');

        this.lockScroll(false);
        this.toggleOverlay(false);
        this.toggleInteraction(false);
    }
};

var resize = function() {
    //var alto_ref = parseInt($('.listado-escenas img').first().outerWidth()/1.33333);
    //$('.listado-escenas img').css('height', alto_ref+'px');
};

(function() {

    var searchOpen = false;
    var menuOpen = false;

    var iosSearch = function() {
        $('#fixed-header').css('position', 'absolute');
        $('#fixed-header').css('top', $(window).scrollTop());

        $(window).scroll(function()	{ $('#fixed-header').css('top', $(window).scrollTop()); } );

        $('.txt-search').blur(function()
        {
            $('#fixed-header').css('position', 'fixed');
            $('#fixed-header').css('top', '0');
            $(window).off('scroll');
        });

        $('.txt-search').focus(function()
        {
            $('#fixed-header').css('position', 'absolute');
            $('#fixed-header').css('top', $(window).scrollTop());
            $(window).scroll(function()	{ $('#fixed-header').css('top', $(window).scrollTop()); } );
        });
    }

    var toggleSearch = function (next) {
        searchOpen = !searchOpen;

        var selector = $('#search-box');

        selector.toggleClass('hidden');
        $('#search-icon')
            .toggleClass('ico-search')
            .toggleClass('icon-up-dir');

        var inputs = selector.find('input');

        if (searchOpen) {
            if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)){iosSearch();}
            inputs.removeAttr('disabled');
            $('#search-input').focus();
        } else {
            inputs.attr('disabled', 'disabled');
        }

        if (next) {
            next();
        }
    };

    var toggleMenu = function (next) {
        menuOpen = !menuOpen;

        var selector = $('#left-menu');

        selector.toggleClass('hidden');
        $('body').toggleClass('has-overlay');
        if (navigator.userAgent.match(/Android 2\./))
            selector.addClass('gingerbread');

        App.lockScroll(menuOpen);
        App.toggleInteraction(menuOpen, '#left-menu', toggleMenu);
        App.toggleOverlay(menuOpen);

        $('#menu-button').find('.sprite')
            .toggleClass('ico-menu')
            .toggleClass('ico-menu-on');

        if (menuOpen) {
            selector.scrollTop(0);
            if (navigator.userAgent.match(/Android 2\./))
                $('html, body').scrollTop( 0 );

            $('.menu-desplegable').addClass('hidden');
        }

        if (next) {
            next();
        }
    };

    $('#search-button').click(function () {
        if (menuOpen) {
            toggleMenu(toggleSearch);
        } else {
            toggleSearch();
        }

        return false;
    });

    $('#menu-button').click(function () {
        if (searchOpen) {
            toggleSearch(toggleMenu);
        } else {
            toggleMenu();
        }

        return false;
    });

    $('#black-overlay').click(function (e) {
        if (!$(this).hasClass('hidden') && e.target.id == 'black-overlay') {
            if (App.interaction.callback) {
                App.interaction.callback();
                return false;
            }

            return false;
        }
    });

    $('.with-submenu').click(function () {
        var that = $(this);
        var submenu = that.next();

        that.toggleClass('link-general-selected');
        that.find('.ico-flecha-off').toggleClass('ico-flecha-on');
        submenu.slideToggle();

        return false;
    });

    $('.selectable').click(function () {
        $(this).toggleClass('selected');
    });

    $('.action-show-more').click(function () {
        var that = $(this);

        var next = that
            .parent()
            .next();

        that.remove();

        next.slideToggle();
        next.removeClass('hidden');

        return false;
    });

    $('.paginator .selected').click(function () {
        return false;
    });

    var urlChanger = $('.url-changer');

    urlChanger.each(function () {
        $(this).find('option').removeAttr('selected');
        $(this).find('option').first().attr('selected', 'selected');
    });


    urlChanger.on('change', function () {
        var selectedElement = $(this.options[this.selectedIndex]);
        var url = selectedElement.data('url');

        this.selectedIndex = 0;

        if (url) {
            document.location.href = url;
        }

        return false;
    });

    $('.toggle-element').click(function () {
        $('#' + $(this).data('hidden-element')).toggle({});

        return false;
    });

    $('.toggle-element-no-animation').click(function () {
        $('#' + $(this).data('hidden-element')).toggleClass('hidden');

        return false;
    });

    $('#letter-open').click(function () {
        $('#menu-desplegado').addClass('hidden');
        App.startInteractionOverlay('#letters-overlay');
        return false;
    });

    $('.overlay-close').click(function () {
        App.endInteractionOverlay('#' + $(this).data('id'));
        return false;
    });

    $('#hotgirls').click(function() {
        // App.trackEvent('Mobile', 'Hot Girls Button');
    });

    $('#live-sex').click(function() {
        // App.trackEvent('Mobile', 'Live Sex Button');
    });
    resize();
})();

window.addEventListener("resize", function() {
    resize();
}, false);



$(window).on("load", function () {
    $('html').addClass('has-transitions');
});

$(document).ready(function () {

    // ================================================
    // LAZYLOAD LOZAD
    // ================================================

    const observer = lozad('.lazy'); // lazy loads elements with default selector as '.lozad'
    var lazyCount = 0;
    observer.observe();

    $(document).ready(function () {
        observer.observe();
        lazyCount = $('.lazy').length;
    });

    /*$(document).on('ajaxStop', function() {
        observer.observe();
    });*/

    var muObserver = new MutationObserver(function (mutations) {
        if ($('.lazy').length != lazyCount) {
            observer.observe();
            lazyCount = $('.lazy').length;
        }
    });

    muObserver.observe($('body')[0], {
        childList: true,
        subtree: true
    });



    // ================================================
    // Mobile pagination behavior
    // ================================================

    var paginationNextBtn = $('.pagination_item--next .btn-pagination');
    var paginationBtnGutter = 4;
    var paginationContainer = $('.pagination');

    function mobilePagination() {

        var nextBtnWidth = paginationNextBtn.width();
        var paginationRightPadding = nextBtnWidth + paginationBtnGutter;

        if (paginationContainer.prop('scrollWidth') > paginationContainer.width()) {
            paginationContainer.css('padding-right', paginationRightPadding).addClass('pagination--scrollable');
            paginationNextBtn.addClass('btn-pagination--sticky');
        } else {
            paginationContainer.css('padding-right', 0).removeClass('pagination--scrollable');
            paginationNextBtn.removeClass('btn-pagination--sticky');
        }
    }

    mobilePagination();

    $(window).on('resize', function (e) {
        mobilePagination();
    });



    // ================================================
    // Sticky footer ad
    // TODO: Rewrite using jQuery instead of vanilla JS
    // ================================================
    // Show sticky banner after a certain amout of scroll (stickyScrollOffset) and close after clicking "X"
    var footerSticky = $('.js-sticky-banner');
    var footerStickyClose = $('.js-close-button');
    var stickyScrollOffset = 150;

    var cSticky = $.cookie('csticky');

    if (!cSticky) {
        $(window).on('scroll', scrollSticky);
    } else {
        footerSticky.addClass("is-disabled");
    }

    // Show/hide sticky on scroll conditions
    function scrollSticky() {
        var scrollHeight = $(document).height();
        var scrollPosition = $(window).height() + $(window).scrollTop();

        //(($(window).scrollTop() + $(window).height()) < $(document.body).height())
        if (($(window).scrollTop() > stickyScrollOffset) && ((scrollHeight - scrollPosition) / scrollHeight !== 0) && ($(window).width() < 768)) {
            // we have scrolled past a certain offset and we are not at the bottom of the page
            footerSticky.addClass("is-active");
            cSticky = $.cookie('csticky');
            if (!cSticky) {
                $.cookie('csticky', 1, {path: '/;'});
            }
        } else {
            footerSticky.removeClass("is-active");
        }

        var d = document.documentElement;
        var offset = d.scrollTop + window.innerHeight;
        var height = d.offsetHeight;

        if ((offset + 50) >= height) {
            //console.log('At the bottom');
            footerSticky.removeClass("is-active");
        }
    }

    // Hide sticky when clicking "X"
    footerStickyClose.on('click', function (e) {
        footerSticky.addClass("is-disabled");
    });

});